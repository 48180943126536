<template>
  <v-card
    color="grey lighten-5"
    class="card-shadow border-radius-general my-4 px-4 px-sm-5"
    flat
  >
    <v-spacer class="py-2" />
    <v-row
      no-gutters
      justify="space-between"
      dense
      class="small-mobile"
      @click.stop="$router.push(`/article/${article.id}`)"
    >
      <v-col class="col-auto mr-2 mr-lg-3">
        <p
          class="grey--text text--darken-1 mb-1 mt-1"
          :class="$vuetify.breakpoint.mobile ? 'text text-s-small-regular':'text text-m-large-regular'"
        >
          {{ article.journal }}
        </p>
      </v-col>
      <v-col class="col-auto d-flex credits">
        <ph-graduation-cap
          weight="light"
          size="20"
          class="clickable white--text circle-icon"
        />
        <p
          class="text text-m-large-regular primary--text pl-1 mb-0"
        >
          {{ article.credits }} CME
        </p>
      </v-col>
    </v-row>

    <p
      class="heading text-l-large-semibold text-left grey--text text--darken-4 mt-3 mb-2 clickable"
      data-test-id="articleTitle"
      @click.stop="$router.push(`/article/${article.id}`)"
    >
      {{ article.title }}
    </p>
    <KeywordsCollapsibleComponent
      v-if="currentUser"
      :keywords="article.keywords"
      @reload-feed="$emit('reload-feed')"
    />
    <v-card-actions class="px-0 pb-4 pb-sm-5">
      <VoteArticleComponent
        :initial-votes="article.diffVotes"
        :initial-upvotes="article.upVotes"
        :initial-downvotes="article.downVotes"
        :user-vote="article.vote_status"
        :article-id="article.id"
      />
      <v-spacer />
      <v-btn
        ripple
        depressed
        height="44px"
        color="grey lighten-5"
        class="primary--text shadow-light-button heading heading-s-small align-self-center unaltered-text-cap letter-spacing-normal px-4"
        @click="goToQuiz"
      >
        Get CME
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import KeywordsCollapsibleComponent from "@/components/feed/KeywordsCollapsibleComponent";
import VoteArticleComponent from "@/components/common/VoteArticleComponent";
import { mapGetters } from 'vuex';
import { PhGraduationCap } from "phosphor-vue";
export default {
  name: "CardComponent",
  components: {
    VoteArticleComponent,
    KeywordsCollapsibleComponent,
    PhGraduationCap
  },
  props: ['article'],
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    goToQuiz() {
      this.$router.push({ name: "article-quiz", params: { id: this.article.id }});
    }
  }
}
</script>
<style>
@media (max-width: 320px) {
  .small-mobile {
    flex-direction: column-reverse;
  }

  .credits {
    margin-bottom: 4px !important;
  }
}
</style>

