<template>
  <div class="d-flex">
    <div
      class="d-inline-block"
      @click="setVote('upvote')"
    >
      <ph-thumbs-up
        weight="light"
        size="18"
        class="clickable vertical-align-middle d-block"
        :class="{ 'secondary--text' : vote === 'upvote', 
                  'grey--text text--darken-1' : vote !== 'upvote' || vote === 'downvote' }"
      />
    </div>
    <label
      class="count-label d-inline-block text text-m-large-regular grey--text text--darken-1 ml-2"
    >{{ showUpvotes }}</label>
    <div
      class="d-inline-block"
      @click="setVote('downvote')"
    >
      <ph-thumbs-down
        weight="light"
        size="18"
        class="clickable vertical-align-middle d-block"
        :class="{ 'error--text' : vote === 'downvote', 
                  'grey--text text--darken-1' : vote === 'upvote' || vote !== 'downvote' }"
      />
    </div>
    <label
      class="count-label d-inline-block text text-m-large-regular grey--text text--darken-1 ml-2"
    >{{ showDownvotes }}</label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PhThumbsUp, PhThumbsDown } from "phosphor-vue";
export default {
  name: "VoteArticleComponent",
  components: { PhThumbsUp, PhThumbsDown },
  props:['initialVotes', "initialUpvotes", "initialDownvotes", 'articleId', 'userVote'],
  data: () => ({
      vote: null,
      totalVotes: 0,
      totalUpvotes: 0,
      totalDownvotes: 0,
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    showUpvotes() {
      return this.showFormattedVotes(this.totalUpvotes);
    },
    showDownvotes() {
      return this.showFormattedVotes(this.totalDownvotes);
    }
  },
  async mounted() {
    await this.$nextTick();
    this.vote =  this.userVote === undefined || this.userVote ==='undefined' ? 'none' : this.userVote;
    this.totalVotes = this.initialVotes || 0;
    this.totalUpvotes = this.initialUpvotes || 0;
    this.totalDownvotes = this.initialDownvotes || 0;

    if ( this.$route.params.data !== undefined && this.articleId === this.$route.params.data.articleId ) {
      this.handleVotesSwap(this.$route.params.data.choice);
    }
  },
  methods:{
    ...mapActions('article', ['upVoteArticle','downVoteArticle']),
    setVote(choice) {
      if ( this.currentUser ) {
        this.handleVotesSwap(choice);
      } else {
        this.$router.push({ name: 'login', params: { data: { articleId: this.articleId, redirect: "vote", choice: choice, nextRoute: this.$route.name } } });
      }
    },
    getIconColor(btn) {
      if ( this.vote === btn ) {
        return btn === 'upvote' ? 'primary--text' : 'error--text';
      } else {
        return 'grey--text text--darken-1';
      }
    },
    handleVotesSwap(choice) {
      if (choice === 'upvote') {
        this.upVote();
      } else {
        this.downVote();
      }
      if ( this.vote === choice ) {
        this.vote = 'none';
      } else {
        this.vote = choice;
      }
    },
    upVote() {
      this.upVoteArticle(this.articleId).then(response => {
        this.totalUpvotes = response.upVotes;
        this.totalDownvotes = response.downVotes;
      }).catch(error=> {
        console.log(error);
      })
    },
    downVote() {
      this.downVoteArticle(this.articleId).then(response => {
        this.totalDownvotes = response.downVotes;
        this.totalUpvotes = response.upVotes;
      }).catch(error=> {
        console.log(error);
      })
    },
    showFormattedVotes(votes) {

      let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumSignificantDigits: 1, maximumSignificantDigits: 3 });
      let result = formatter.format(votes);

      return result;
    }
  }
}
</script>

<style scoped>
@media (min-width: 320px) {
  .count-label {
    width: 41px
  }
}

@media (min-width: 375px) {
  .count-label {
     width: 50px;
  }
}
</style>
