<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div
          v-if="hasToShowKeywordsPanel"
          :class="collapsed ? 'collapsed':'keywords-container'"
          class="text-left mt-0 py-1 px-1"
        >
          <KeywordChipComponent
            v-for="keyword in localKeywords"
            :key="keyword+uniqueKeysHelper()"
            :keyword="keyword"
            :user-keywords="currentUserKeywords"
            :list-mode="listMode"
            @subscribe-keyword="handleSubscribeToKeyword"
            @unsubscribe-keyword="handleUnsubscribeToKeyword"
          />
          <KeywordChipComponent
            v-if="windowSize > mobileKeywordsTreshold && keywords.length > 1 && collapsed && remainingKeywordsToShow > 0"
            :key="uniqueKeysHelper()"
            :keyword="remainingKeywordsToShow"
            :is-plus-button="true"
            @click-plus-remainig="toogleClass()"
          />
          <br v-if="remainingKeywordsToShow > 0">
          <KeywordChipComponent
            v-for="keyword in localKeywordsRemainings"
            :key="keyword+uniqueKeysHelper()"
            :keyword="keyword"
            :user-keywords="currentUserKeywords"
            @subscribe-keyword="handleSubscribeToKeyword"
            @unsubscribe-keyword="handleUnsubscribeToKeyword"
          />
        </div>
        <v-btn
          v-if="!hasToShowKeywordsPanel && !disableAccordion && keywords.length > MAXIMUM_KEYWORDS_TO_SHOW"
          ripple
          depressed
          height="36px"
          color="white"
          class="text text-l-small-regular grey--text text--darken-3 align-self-center unaltered-text-cap letter-spacing-normal"
          @click="toogleClass()"
        >
          +{{ keywords.length }} interests
        </v-btn>
      </v-col>
      <v-col
        v-show="showToggleKeywordsBtn && !disableAccordion && localKeywordsRemainings.length > MAXIMUM_KEYWORDS_TO_SHOW"
        cols="1"
      >
        <ph-caret-up
          v-if="!collapsed"
          weight="thin"
          size="18"
          class="clickable grey--text text--lighten-1 mt-0"
          :class="collapsed ? 'tooglebtn-open':'tooglebtn'"
          @click="toogleClass"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { v4 as uuidv4 } from 'uuid';
import KeywordChipComponent from '@/components/common/KeywordChipComponent';
import { PhCaretUp } from "phosphor-vue";
export default {
  name: "KeywordsCollapsibleComponent",
  components: { KeywordChipComponent, PhCaretUp },
  props: ['keywords', 'disableAccordion', "listMode"],
  data: () => ({
    collapsed: true,
    keywordsToSubscribe: [],
    keywordsToUnsubscribe: [],
    enumOptions: { maxAllowedKeywordsLengthWithoutToggle: 35, maxAllowedKeywordsLengthWithoutToggleTablets: 50, maxAllowedKeywordsLengthWithoutTogglePC: 70 },
    toasts: [],
    windowSize: 0,
    uniqueKeysHelper: uuidv4,
    localKeywords: [],
    localKeywordsRemainings: [],
    mobileKeywordsTreshold: 767,
    smallDesktopsTreshold: 1024,
    MAXIMUM_KEYWORDS_TO_SHOW: 5,
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('keywords', ['currentUserKeywords']),
    showToggleKeywordsBtn() {
      return Object.values(this.keywords || [])?.reduce( (totalKeywordsLength, keyword) => {
        totalKeywordsLength = totalKeywordsLength + keyword.length;
        return totalKeywordsLength;
      }, 0) > this.enumOptions.maxAllowedKeywordsLengthWithoutToggle || false || this.windowSize < this.mobileKeywordsTreshold;
    },
    remainingKeywordsToShow() {
      return this.calculateRemainingKeywords();
    },
    hasToShowKeywordsPanel() {
      let result = true;

      if (this.windowSize < this.mobileKeywordsTreshold) {
        result = !this.collapsed;
      }

      if (this.windowSize >= this.mobileKeywordsTreshold) {
        result = true;
      }

      if(this.disableAccordion) {
        result = true;
      }

      return result;
    }
  },
  mounted() {
    this.windowSize = window.innerWidth;

    if(this.disableAccordion) {
      this.collapsed = false;
    }

    this.prepareKeywordsRendering();
    
    if (this.localKeywordsRemainings.length < this.MAXIMUM_KEYWORDS_TO_SHOW) {
      this.collapsed = false;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResizeWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResizeWindow);
  },
  methods: {
    ...mapActions('keywords', ['subscribeKeywordsToUser', 'unsubscribeKeywordsToUser']),
    toogleClass: function () {
      this.collapsed = !this.collapsed;
    },
    async handleSubscribeToKeyword(keyword) {
      if(this.currentUser) {

        this.keywordsToSubscribe.push(keyword);

        const response = await this.subscribeKeywordsToUser({ keywords: this.keywordsToSubscribe });

        if (response.data) {
          this.$emit('reload-feed');
        }

      } else {
        this.$router.push({ name: "login", params: { data: { keyword: keyword, redirect: "subscribe-to-keyword", nextRoute: 'home' } }});
      }
    },
    async handleUnsubscribeToKeyword(keyword) {
      if(this.currentUser) {

        this.keywordsToUnsubscribe.push(keyword);

        const response = await this.unsubscribeKeywordsToUser({ keywords: this.keywordsToUnsubscribe })

        if (response.data) {
          this.$emit('reload-feed');
        }
      }
    },
    handleResizeWindow(e) {
      this.windowSize = window.innerWidth;

      this.prepareKeywordsRendering();
    },
    calculateRemainingKeywords() {

      if (this.disableAccordion) {
        return 0;
      }

      let remainingKeywords = 0;

      Object.values(this.keywords || [])?.reduce( (totalKeywordsLength, keyword, index) => {
        totalKeywordsLength = totalKeywordsLength + keyword.length;

        if(totalKeywordsLength > this.enumOptions.maxAllowedKeywordsLengthWithoutToggleTablets && remainingKeywords === 0 && this.windowSize > this.mobileKeywordsTreshold && this.windowSize < this.smallDesktopsTreshold) {
          remainingKeywords = this.keywords.length - (index + 1);
        }

        if(totalKeywordsLength > this.enumOptions.maxAllowedKeywordsLengthWithoutTogglePC && remainingKeywords === 0 && this.windowSize >= this.smallDesktopsTreshold) {
          remainingKeywords = this.keywords.length - (index + 1);
        }

        return totalKeywordsLength;
      }, 0);

      if (this.windowSize < this.mobileKeywordsTreshold) {
        return this.keywords.length;
      }

      return (remainingKeywords ? remainingKeywords + 1 : 0);

    },
    prepareKeywordsRendering() {

      let remainingKeywordsCount = this.calculateRemainingKeywords();

      let startingPositionPointToHideKeywords = this.keywords.length - remainingKeywordsCount;

      if(this.keywords.length > 1 && !this.disableAccordion) {
        this.localKeywords =  this.keywords.filter( (value, index) => {
          return index < startingPositionPointToHideKeywords;
        });

        this.localKeywordsRemainings =  this.keywords.filter( (value, index) => {
          return index >= startingPositionPointToHideKeywords;
        });
      } else {
        this.localKeywords =  this.keywords;
      }
    }
  },
}
</script>

<style scoped>

.keywords-container {
  margin: 0px 0px;
  padding-left: 1px;
  transition: max-height 500ms, transform 2s;
  overflow: hidden;
}

.collapsed {
  margin: 10px 0px;
  max-height: 44px;
  overflow: hidden;
  transition: max-height 500ms, transform 2s;
}

.tooglebtn {
  margin-top: 12PX;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  -ms-transition: -ms-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
}


.tooglebtn-open {
  margin-top: 12PX;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
</style>
