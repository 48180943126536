<template>
  <div>
    <v-row class="mt-8">
      <v-col class="d-flex justify-space-between">
        <h1 class="heading heading-m-large primary--text">
          Welcome
        </h1>
        <ph-x
          class="clickable grey--text text--lighten-1"
          weight="light"
          size="24"
          @click="closeWelcome"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <p class="text text-l-large-regular mb-0">
          Acapedia is where the brightest minds of the world come together to share knowledge and expertise. Read articles from peers across the globe and obtain CME credit along the way.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-btn
          ripple
          depressed
          block
          height="40px"
          class="heading heading-s-small white--text unaltered-text-cap shadow-dark-button cold-gradient"
          @click="goToSpecialtiesList"
        >
          Find your specialty
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { PhX } from "phosphor-vue";
export default {
  components: { PhX },
  methods: {
    goToSpecialtiesList() {
      this.$cookies.set("acapedia_NonUserWelcomeClosed", true, Infinity);
      this.$router.push({ name: "specialties" });
    },
    closeWelcome() {
      this.$cookies.set("acapedia_NonUserWelcomeClosed", true, Infinity);
      this.$emit('close-non-user-welcome');
    }
  }
}
</script>

<style>

</style>
