<template>
  <div>
    <ProgressBarComponent
      v-if="loading"
      :title="'feed'"
    />
    <CardComponent
      v-for="(article, index) in articles"
      :key="index+uniqueKeysHelper()"
      :article="article"
      @reload-feed="reloadFeed"
    />
    <div
      v-if="articles !== null && articles.length === 0"
      class="py-4 mt-sm-15 mt-lg-15"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          class="text-center"
        >
          <MagnifierImageComponent />
        </v-col>
      </v-row>
      <v-row 
        justify="center"
        class="title-margin"
      >
        <v-col
          cols="10"
          sm="7"
          xl="5"
          class="text-center mobile"
        >
          <h1
            class="mb-3 primary--text" 
            :class="isMobile"
          >
            Don't see any articles?
          </h1>
          <p
            v-if="currentUser"
            class="text text-l-large-regular extra-small"
          >
            It seems that your specialty doesn’t have any submissions yet. Find popular articles in other specialties.
          </p>
          <p
            v-if="!currentUser"
            class="text text-l-large-regular extra-small"
          >
            It seems that you haven’t signed up. If you want a customized feed and to obtain CME and MOC credit, you must register to Acapedia Professionals.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="auto"
          class="text-center"
        >
          <v-btn
            v-if="currentUser"
            ripple
            depressed
            block
            class="heading heading-s-small white--text unaltered-text-cap shadow-dark-button cold-gradient"
            @click="goToMainFeed"
          >
            Browse all articles
          </v-btn>
          <v-btn
            v-if="!currentUser"
            ripple
            depressed
            block
            class="heading heading-s-small white--text unaltered-text-cap shadow-dark-button cold-gradient"
            @click="goToRegister"
          >
            Sign up now
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/feed/CardComponent";
import ApprovedIconComponent from "@/assets/ApprovedIconComponent";
import ArrowDownIconComponent from "@/assets/ArrowDownIconComponent";
import ArrowUpIconComponent from "@/assets/ArrowUpIconComponent";
import MagnifierImageComponent from "@/assets/MagnifierImageComponent";
import ProgressBarComponent from '@/components/common/ProgressBarComponent';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from "vuex";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { CardComponent, ApprovedIconComponent, ArrowDownIconComponent, ArrowUpIconComponent, MagnifierImageComponent, ProgressBarComponent, uuidv4 },
  props: ['specialtyId', 'myFeed', 'reload'],
  data: () => ({
    articles: null,
    optionToSort: 'Top',
    loading: true,
    uniqueKeysHelper: uuidv4,
    enumSortOptions: { top: 'Top' }
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    isMobile(){
      if (this.$vuetify.breakpoint.name === 'xs') {
        return "heading heading-m-small";
      }
      return "heading heading-m-large";
    },
  },
  watch: {
    reload: function () {
      this.loadFeed();
    }
  },
  async mounted() {
    this.loadFeed();
  },
  methods: {
    ...mapActions('feed', ['getFeed', 'getMyFeed', 'sortByTop']),
    ...mapActions('specialties', ['getSpecialtiesSelected']),
    ...mapActions('keywords', ['getUserKeywords']),
    loadFeed() {
      if(!this.currentUser && this.myFeed === 'My feed') {
        this.articles = [];
        this.loading = false;
      } else {
        
        const getCurrentFeedPromise = this.myFeed == 'My feed' ? this.getMyFeed() : this.sortByTop();

        getCurrentFeedPromise.then( response => {
          this.articles = response.data;
          this.loading = false;
          this.$emit("show-buttons", this.articles.length > 0, this.myFeed);
        }).catch( error => {
            this.$toasted.global.showError({ message: error.response.data ? error.response.data.message : 'Unexpected error' });
        });
      }

    },
    reloadFeed() {
      this.loadFeed();
      this.$emit('reload-all-feeds');
    },
    goToMainFeed() {
      this.$emit('browse-in-main-feed');
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    }
  },
}
</script>

<style>
.btn-filter-anycme {
  border-color: #F1F1F5!important;
  border-radius: 15px;
  min-height: 36px;
  height: auto;
}

.field-outline-light fieldset {
  border: 1px solid #F1F1F5 !important;
}

.field-background-white fieldset {
  background-color: white !important;
}

.v-menu__content {
  border-color: #F1F1F5!important;
  box-shadow:none!important;
}

@media (max-width: 320px) {
  .mobile {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .extra-small {
    margin-bottom: 0 !important;
  }

  .title-margin {
    margin-top: 0 !important;
  }

}

@media (max-height: 815px) {
  .mobile {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .extra-small {
    margin-bottom: 0 !important;
  }

  .title-margin {
    margin-top: 0 !important;
  }

}

</style>
