<template>
  <div class="row justify-center">
    <v-col
      v-if="shouldShownWelcomeToNonUser"
      cols="12"
      lg="10"
      class="pb-0"
    >
      <WelcomeNonUserComponent
        @close-non-user-welcome="getNonUserWelcomeCookie"
      />
      <v-divider class="custom-divider-position mt-6" />
    </v-col>
    <v-col
      cols="12"
      lg="10"
    >
      <v-card class="theme-tabs">
        <v-tabs
          v-model="tab"
          background-color="white"
          class="responsive-feed-tab-container mt-3"
        >
          <v-tabs-slider color="secondary" />
          <v-tab
            v-for="feedTab in feedTabs"
            :key="feedTab.key"
            class="tab-mobile"
          >
            <span
              class="text text-l-small-regular letter-spacing-normal"
              :class="feedTab.title | handleTitleTabClass"
            >{{ feedTab.title }}</span>
          </v-tab>
          <div
            v-if="reloadAllFeeds"
            class="filter-container"
          >
            <v-btn
              v-if="hasToShowSearchButton"
              ripple
              depressed
              color="grey lighten-5"
              class="
                primary--text
                shadow-light-button
                text text-m-large-regular
                align-self-center
                unaltered-text-cap
                letter-spacing-normal
                mr-2
                btn-mobile
              "
            >
              <ph-magnifying-glass
                weight="thin"
                size="24"
                class="clickable"
              />
            </v-btn>
            <v-menu 
              v-if="hasToShowFilterButton"
              bottom
              left 
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="!isMobile"
                  ripple
                  depressed
                  color="grey lighten-5"
                  class="
                    primary--text
                    shadow-light-button
                    text text-m-large-regular
                    align-self-center
                    unaltered-text-cap
                    letter-spacing-normal
                    mr-1
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <ph-sliders
                    weight="thin"
                    size="24"
                    class="clickable mr-sm-2"
                  />
                  Filter
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  class="text text-m-large-regular"
                  @click="goToSpecialtiesList"
                >
                  By specialty
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu 
              v-if="hasToShowFilterButton"
              bottom
              left
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="isMobile"
                  ripple
                  depressed
                  color="grey lighten-5"
                  class="
                    primary--text
                    shadow-light-button
                    text text-m-large-regular
                    align-self-center
                    unaltered-text-cap
                    letter-spacing-normal
                    mr-1
                    btn-mobile
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <ph-sliders
                    weight="thin"
                    size="24"
                    class="clickable"
                  />
                </v-btn>
              </template>

              <v-list class="grey lighten-3">
                <v-list-item
                  class="text text-m-large-regular"
                  @click="goToSpecialtiesList"
                >
                  By specialty
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="fill-height theme-background-color-feed"
        >
          <v-tab-item
            v-for="feedTab in feedTabs"
            :key="feedTab.key"
          >
            <FeedListComponent
              :specialty-id="feedTab.id"
              :my-feed="feedTab.title"
              :reload="reloadCountToUpdateFeed"
              @reload-all-feeds="reloadAllFeeds"
              @browse-in-main-feed="browseInMainFeed"
              @show-buttons="checkHasToShowButtons"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import FeedListComponent from "@/components/feed/FeedListComponent";
import { mapActions, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import WelcomeNonUserComponent from "@/components/onboarding/WelcomeNonUserComponent.vue";
import { PhMagnifyingGlass, PhSliders } from "phosphor-vue";
export default {
  mounted() {
    if (!this.loaded) {
      this.loadMySpecialties();
      this.loadKeywords();
    }

    if (!this.currentUser) {
      this.tab = this.enumFeedTabs.popular;
      this.getNonUserWelcomeCookie();
    }
  },
  name: "FeedLayoutComponent",
  components: { FeedListComponent, WelcomeNonUserComponent, PhMagnifyingGlass, PhSliders },
  filters: {
    handleTitleTabClass: (title) => {
      return title === "Popular" ? "text-initial" : "text-capitalize";
    },
  },
  data() {
    return {
      feedTabs: [
        { tab: 0, title: "My feed", key: uuidv4(), articles: null },
        { tab: 1, title: "Popular", key: uuidv4(), articles: null },
      ],
      tab: 0,
      enumFeedTabs: { myFeed: 0, popular: 1 },
      loaded: false,
      reloadCountToUpdateFeed: 0,
      showKeywordsDialog: false,
      topKeywords: [],
      keywordsToSubscribe: [],
      keywordsToUnsubscribe: [],
      cookieNonUserWelcomeStatus: false
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("keywords", ["currentUserKeywords"]),
    shouldShownWelcomeToNonUser() {
      return !this.currentUser && !this.cookieNonUserWelcomeStatus;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    hasToShowSearchButton() {
      if (this.isMobile) {
        return this.feedTabs[this.tab].articles;
      }
    },
    hasToShowFilterButton() {
      return this.feedTabs[this.tab].articles;
    }
  },
  methods: {
    ...mapActions("specialties", ["getSpecialtiesSelected"]),
    ...mapActions("keywords", [
      "getUserKeywords",
      "getTopKeywords",
      "subscribeKeywordsToUser",
      "unsubscribeKeywordsToUser",
    ]),
    async loadMySpecialties() {
      if (this.currentUser) {
        this.getSpecialtiesSelected()
          .then((response) => {
            if (response.data.specialties.length && !this.loaded) {
              this.loaded = true;
            }
          })
          .catch((error) => {
            if (this.currentUser)
              this.$toasted.global.showError({
                message: error.response.data
                  ? error.response.data.message
                  : "Unexpected error",
              });
          });
      }
    },
    async loadKeywords() {
      if (this.currentUser) {
        await this.getUserKeywords();
        this.getTopKeywords().then((response) => {
          this.topKeywords = response.data;
        });
        this.showKeywordsDialog = this.currentUserKeywords.length === 0;
      }
    },
    reloadAllFeeds() {
      this.reloadCountToUpdateFeed = this.reloadCountToUpdateFeed + 1;
    },
    handleSubscribeToKeyword(keyword) {
      if (this.currentUser) {
        this.keywordsToSubscribe.push(keyword);

        this.subscribeKeywordsToUser({ keywords: this.keywordsToSubscribe })
          .then((response) => {
            this.$toasted.global.showSuccess({
              message: response.data.message,
            });
            this.reloadAllFeeds();
          })
          .catch((error) => {
            this.$toasted.global.showError({ message: error.message });
          });
      } else {
        this.$router.push({
          name: "login",
          params: {
            data: {
              keyword: keyword,
              redirect: "subscribe-to-keyword",
              nextRoute: "home",
            },
          },
        });
      }
    },
    handleUnsubscribeToKeyword(keyword) {
      if (this.currentUser) {
        this.keywordsToUnsubscribe.push(keyword);

        this.unsubscribeKeywordsToUser({ keywords: this.keywordsToUnsubscribe })
          .then((response) => {
            this.$toasted.global.showSuccess({
              message: response.data.message,
            });
            this.reloadAllFeeds();
          })
          .catch((error) => {
            this.$toasted.global.showError({ message: error.message });
          });
      }
    },
    browseInMainFeed() {
      this.tab = this.enumFeedTabs.popular;
      this.reloadAllFeeds();
    },
    getNonUserWelcomeCookie() {
      this.cookieNonUserWelcomeStatus = this.$cookies.get(
        "acapedia_NonUserWelcomeClosed"
      );
    },
    goToSpecialtiesList() {
      this.$router.push({ name: "specialties" });
    },
    checkHasToShowButtons(articles, tab) {
      if (this.feedTabs[this.enumFeedTabs.myFeed].title === tab) {
        return this.feedTabs[this.enumFeedTabs.myFeed].articles = articles
      } 

      return this.feedTabs[this.enumFeedTabs.popular].articles = articles
    }
  },
};
</script>

<style scoped>
.theme-tabs {
  box-shadow: none !important;
}

.theme-tabs >>> .v-window {
  overflow: visible;
}

.theme-background-color-feed {
  background-color: var(--v-background-base) !important;
}
.clickable:hover {
  cursor: pointer;
}
.theme-dialog {
  background: #ffffff;
  box-shadow: none !important;
  border-radius: 15px !important;
}
.v-dialog {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px !important;
}
.heading-color-gray-dark {
  color: #44444f;
}
.text-initial {
  text-transform: initial;
}
.description {
  color: #828282;
}
.responsive-feed-tab-container >>> .v-tabs-slider-wrapper {
  height: 3px !important;
}
.responsive-feed-tab-container >>> .v-item-group {
  background-color: var(--v-background-base) !important;
}

.v-tabs-slider {
  height: 1px;
}
.filter-container {
  align-items: center;
  display: flex;
  margin-left: auto;
  text-align: left;
}

@media (max-width: 810px) {
  .custom-divider-position {
    min-width: 910px !important;
    margin-left: -100px;
  }
}

@media (max-width: 375px) {
  .responsive-feed-tab-container >>> .v-slide-group__prev {
    display: none !important;
  }

  .responsive-feed-tab-container >>> .v-slide-group__next {
    display: none !important;
  }

  .btn-mobile {
    height: 36px !important;
    min-width: 36px !important;
    padding: 0 0 0 10px !important;
    /* padding-left: 10px !important; */
  }

  .tab-mobile {
    min-width: 67px !important;
    padding: 0 !important;
  }

}
</style>
